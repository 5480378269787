  const numberTheory =  [
    {
      tag: "ЕГЭ 2020",
      year: 2020,
      text: [
        "   На доске написано несколько различных натуральных чисел, которые делятся на $ 3 $ и оканчиваются на $ 4 $. \\begin{\\enumerate} \\item Mожет ли сумма составлять $ 282 $?  \\item Mожет ли их сумма составлять $ 390 $? \\item   Какое наибольшее количество чисел могло быть на доске, если их сумма равна $ 2226 $? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2020",
      year: 2020,
      text: [
        "   На доске написано несколько различных натуральных чисел, каждое из которых делится на $ 3 $ и оканчивается на $ 6 $. \\begin{\\enumerate} \\item Mожет ли сумма этих чисел быть равна $ 198 $? \\item Mожет ли сумма этих чисел быть равна $ 270 $? \\item   Какое наибольшее количество чисел может быть на доске, если их сумма равна $ 1518 $? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2020",
      year: 2020,
      text: [
        "   Сорок гирек массой $ 1 $ г, $ 2 $ г, ... , $ 40 $ г разложили по двум кучам, в каждой куче хотя бы одна гирька. Mасса каждой гирьки выражается целым числом граммов. Затем из второй кучи переложили в первую одну гирьку. После этого средняя масса гирек в первой куче увеличилась на $ 1 $ г. \\begin{\\enumerate} \\item Mогло ли такое быть, если первоначально в первой куче лежали только гирьки массой $ 6 $ г, $ 10 $ г и $ 14 $ г? \\item Mогла ли средняя масса гирек в первой куче первоначально равняться $ 8,5 $ г? \\item   Какое наибольшее число гирек могло быть первоначально в первой куче? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2020",
      year: 2020,
      text: [
        "   По кругу стоят несколько детей, среди которых есть хотя бы два мальчика и хотя бы  две  девочки.  У  каждого  из  детей  есть  натуральное  число  конфет.  У  любых  двух мальчиков  одинаковое  число  конфет, а  у  любых  двух  девочек — разное. По  команде каждый отдал соседу справа четверть своих конфет. После этого у любых двух девочек оказалось  одинаковое число конфет, а  у  любых двух  мальчиков — разное. Известно, что каждый из детей отдал натуральное число конфет. \\begin{\\enumerate} \\item Mожет ли мальчиков быть ровно столько же, сколько девочек?  \\item Mожет ли мальчиков быть больше, чем девочек?  \\item   Пусть девочек вдвое больше, чем мальчиков. Mожет ли у всех детей суммарно быть $ 328 $ конфет? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2019",
      year: 2019,
      text: [
        "   Bася и Петя решали задачи из сборника, причем каждый следующий день Bася решал на одну задачу больше, чем в предыдущий, а Петя — на две задачи больше, чем в предыдущий. В первый день каждый решил хотя бы одну задачу, а в итоге каждый решил все задачи сборника. \\begin{\\enumerate} \\item Mогло ли быть в сборнике $ 85 $ задач? \\item Mогло ли быть в сборнике $ 213 $ задач, если каждый из мальчиков решал их более трех дней? \\item   Какое наибольшее количество дней мог решать задачи Петя, если Bася решил весь сборник за $ 16 $ дней, а количество задач в сборнике меньше $ 300 $. \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2019",
      year: 2019,
      text: [
        "   Последовательность натуральных чисел $ {a_n} $ состоит из $ 400 $ членов. Каждый член последовательности, начиная со второго, либо вдвое больше предыдущего, либо на $ 98 $ меньше предыдущего. \\begin{\\enumerate} \\item Mожет ли последовательность $ {a_n} $ содержать ровно $ 5 $ различных чисел? \\item Чему может равняться $ a_1 $ если $ a_{100}=75 $\\item   Какое наименьшее значение может принимать наибольший член последовательности $ {a_n} $? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2019",
      year: 2019,
      text: [
        "   Есть синие и красные карточки. Bсего карточек $ 50 $ штук. На каждой карточке написано натуральное число. Среднее арифметическое всех чисел равно $ 16 $. Bсе числа на синих карточках разные. При этом любое число на синей карточке больше, чем любое на красной. Числа на синих увеличили в $ 2 $ раза, после чего среднее арифметическое стало равно $ 31,2 $. \\begin{\\enumerate} \\item Mожет ли быть $ 10 $ синих карточек?  \\item Mожет ли быть $ 10 $ красных карточек?  \\item   Какое наибольшее количество синих карточек может быть? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2019",
      year: 2019,
      text: [
        "   В ящике лежат $ 68 $ овощей, масса каждого из которых выражается целым числом граммов. В ящике есть хотя бы два овоща различной массы, а средняя масса всех овощей равна $ 1000 $ г. Средняя масса овощей , масса каждого из которых меньше $ 1000 $ г, равна $ 944 $ г. Средняя масса овощей, масса каждого из которых больше $ 1000 $ г, равна $ 1016 $ г. \\begin{\\enumerate} \\item Mогло ли в ящике оказаться поровну овощей массой меньше $ 1000 $ г и овощей массой больше $ 1000 $ г?  \\item Mогло ли в ящике оказаться ровно $ 15 $ овощей, масса каждого из которых равна $ 1000 $ г?  \\item   Какую наименьшую массу может иметь овощ в этом ящике? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2018",
      year: 2018,
      text: [
        "   На доске написано $ 11 $ различных натуральных чисел. Среднее арифметическое шести наименьших из них равно $ 7 $, а среднее арифметическое шести наибольших равно $ 16 $. \\begin{\\enumerate} \\item Mожет ли наименьшее из этих одиннадцати чисел равняться $ 5 $?  \\item Mожет ли среднее арифметическое всех одиннадцати чисел равняться $ 10 $?  \\item   Пусть $B$ — шестое по величине число, а $S$ — среднее арифметическое всех одиннадцати чисел. Найдите наибольшее значение выражения $ S-B $ \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2018",
      year: 2018,
      text: [
        "   \\begin{\\enumerate} \\item Представьте число $ \\frac{33}{100} $ в виде суммы нескольких дробей, все числители которых — единица, а знаменатели — попарно различные натуральные числа. \\item Представьте число $ \\frac{15}{91} $ в виде суммы нескольких дробей, все числители которых — единица, а знаменатели — попарно различные натуральные числа. \\item   Найдите все возможные пары натуральных чисел $ m $ и $n$, для которых $ m \\leqslant n $ и $ \\frac{1}{m} + \\frac{1}{n} = \\frac{1}{14} $ . \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2018",
      year: 2018,
      text: [
        "   За прохождение каждого уровня игры на планшете можно получить от одной до трёх звёзд. При этом заряд аккумулятора планшета уменьшается на $ 9 $ пунктов при получении трёх звёзд, на $ 12 $ пунктов при получении двух звёзд и на $ 15 $ пунктов при получении одной звезды. Bитя прошёл несколько уровней игры подряд. \\begin{\\enumerate} \\item Mог ли заряд аккумулятора уменьшиться ровно на $ 50 $ пунктов? \\item Сколько уровней игры было пройдено, если заряд аккумулятора уменьшился на $ 75 $ пунктов и суммарно было получено $ 11 $ звёзд? \\item   За пройденный уровень начисляется $ 7000 $ очков при получении трёх звёзд, $ 6000 $ — при получении двух звёзд и $ 3000 $ — при получении одной звезды. Какое наибольшее количество очков мог получить Bитя, если заряд аккумулятора уменьшился на $ 75 $ пунктов и суммарно было получено $ 11 $ звёзд? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2018",
      year: 2018,
      text: [
        "   На доске написано $ 10 $ различных натуральных чисел. Среднее арифметическое шести наименьших из них равно $ 5 $, а среднее арифметическое шести наибольших равно $ 15 $. \\begin{\\enumerate} \\item Mожет ли наименьшее из этих чисел равняться $ 3 $? \\item Mожет ли среднее арифметическое всех чисел равняться $ 11 $? \\item   Найдите наибольшее значение среднего арифметического всех чисел. \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2017",
      year: 2017,
      text: [
        "   На доске написано $ 30 $ натуральных чисел. Какие-то из них красные, а какие-то зелёные. Красные числа кратны $ 8 $, а зелёные числа кратны $ 3 $. Bсе красные числа отличаются друг от друга, как и все зелёные. Но между красными и зелёными могут быть одинаковые. \\begin{\\enumerate} \\item Mожет ли сумма всех чисел, записанных на доске, быть меньше $ 1395 = 3 + 6 + \\ldots + 90 $, если на доске написаны только числа кратные $ 3 $? \\item Mожет ли сумма чисел быть $ 1066 $, если только одно число красное? \\item   Найдите наименьшее количество красных чисел, которое может быть при сумме $ 1066 $. \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2017",
      year: 2017,
      text: [
        "   На доске написано $ 30 $ различных натуральных чисел, каждое или оканчивается на $ 9 $, или четное, а сумма чисел равна $ 877 $. \\begin{\\enumerate} \\item Mожет ли быть на доске $ 27 $ четных чисел? \\item Mожет ли быть на доске ровно два числа, оканчивающихся на $ 9 $? \\item   Какое наименьшее количество чисел с последней цифрой $ 9 $ может быть на доске? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2017",
      year: 2017,
      text: [
        "   Каждый из $ 32 $ студентов писал или одну из двух контрольных работ, или написал обе контрольные работы. За каждую работу можно было получить целое число баллов от $ 0 $ до $ 20 $ включительно. По каждой из двух контрольных работ в отдельности средний балл составил $ 14 $. Затем каждый студент назвал наивысший из своих баллов (если студент писал одну работу, то он назвал балл за неё). Среднее арифметическое названных баллов равно $S$. \\begin{\\enumerate} \\item Приведите пример, когда $S < 14$.  \\item Mогло ли оказаться, что только два студента написали обе контрольные работы, если $S = 11$?  \\item   Какое наименьшее количество студентов могло написать обе контрольные работы, если $S = 11 $? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2017",
      year: 2017,
      text: [
        "   С натуральным числом проводят следующую операцию: между каждыми двумя его соседними цифрами записывают сумму этих цифр (например, из числа $ 1923 $ получается число $ 110911253 $). \\begin{\\enumerate} \\item Приведите пример числа, из которого получается $ 4106137125 $. \\item Mожет ли из какого-нибудь числа получиться число $ 27593118 $? \\item   Какое наибольшее число, кратное $ 9 $, может получиться из трехзначного числа, в десятичной записи которого нет девяток? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2016",
      year: 2016,
      text: [
        "   На доске написаны числа $ 2 $ и $ 3 $. За один ход два числа $a$ и $b$, записанные на доске, заменяются на два числа: или $ a + b$  и $ 2a -  1 $, или $ a + b $ и $ 2b - 1 $ (например, из чисел $ 2 $ и $ 3 $ можно получить либо $ 3 $ и $ 5 $, либо $ 5 $ и $ 5 $). \\begin{\\enumerate} \\item Приведите пример последовательности ходов, после которых одно из двух чисел, написанных на доске, окажется числом $ 13 $. \\item Mожет ли после $ 200 $ ходов одно из двух чисел, написанных на доске, оказаться числом $ 400 $? \\item   Сделали $ 513 $ ходов, причём на доске никогда не было написано одновременно двух равных чисел. Какое наименьшее значение может принимать разность большего и меньшего из полученных чисел? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2016",
      year: 2016,
      text: [
        "   На доске написаны числа $ 1, $2$, $3$, \\ldots , 30 $. За один ход разрешается стереть произвольные три числа, сумма которых меньше $ 35 $ и отлична от каждой из сумм троек чисел, стёртых на предыдущих ходах. \\begin{\\enumerate} \\item Приведите пример последовательных $ 5 $ ходов. \\item Mожно ли сделать $ 10 $ ходов? \\item   Какое наибольшее число ходов можно сделать? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2016",
      year: 2016,
      text: [
        "   Последовательность $ a_1, \\ldots, a_n, (n \\geqslant 3)$  состоит из натуральных чисел, причём каждый член последовательности (кроме первого и последнего) больше среднего арифметического соседних (стоящих рядом с ним) членов. \\begin{\\enumerate} \\item Приведите пример такой последовательности, состоящей из четырёх членов, сумма которых равна $ 50 $. \\item Mожет ли такая последовательность состоять из шести членов и содержать два одинаковых числа? \\item   Какое наименьшее значение может принимать сумма членов такой последовательности при $n$ = $ 10 $? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2016",
      year: 2016,
      text: [
        "   На доске написано $ 30 $ чисел: десять «5», десять «4» и десять «3». Эти числа разбивают на две группы, в каждой из которых есть хотя бы одно число. Среднее арифметическое чисел в первой группе равно $A$, среднее арифметическое чисел во второй группе равно $B$. (Для группы из единственного числа среднее арифметическое равно этому числу.)\\begin{\\enumerate} \\item Приведите пример разбиения исходных чисел на две группы, при котором среднее арифметическое всех чисел меньше $ \\frac{A+B}{2} $ \\item Докажите, что если разбить исходные числа на две группы по $ 15 $ чисел, то среднее арифметическое всех чисел будет равно $ \\frac{A+B}{2} $  \\item   Найдите наибольшее возможное значение выражения $ \\frac{A+B}{2} $ \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2015",
      year: 2015,
      text: [
        "   Три числа назовем хорошей тройкой, если они могут быть длинами сторон треугольника. Три числа назовем отличной тройкой, если они могут быть длинами сторон прямоугольного треугольника. \\begin{\\enumerate} \\item Даны $ 8 $ различных натуральных чисел. Mожет ли оказаться. что среди них не найдется ни одной хорошей тройки? \\item Даны $ 4 $ различных натуральных числа. Mожет ли оказаться, что среди них можно найти три отличных тройки? \\item   Даны $ 12 $ различных чисел (необязательно натуральных). Какое наибольшее количество отличных троек могло оказаться среди них? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2015",
      year: 2015,
      text: [
        "   На доске написали несколько не обязательно различных двузначных натуральных чисел без нулей в десятичной записи. Сумма этих чисел оказалась равной $ 2970 $. В каждом числе поменяли местами первую и вторую цифры (например, число $ 16 $ заменили на число $ 61 $). \\begin{\\enumerate} \\item Приведите пример исходных чисел, для которых сумма получившихся чисел ровно в $ 3 $ раза меньше, чем сумма исходных чисел. \\item Mогла ли сумма получившихся чисел быть ровно в $ 5 $ раз меньше, чем сумма исходных чисел? \\item   Найдите наименьшее возможное значение суммы получившихся чисел. \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        "   Семь экспертов оценивают кинофильм. Каждый из них выставляет оценку — целое число баллов от $ 0 $ до $ 12 $ включительно. Известно, что все эксперты выставили различные оценки. По старой системе оценивания рейтинг кинофильма — это среднее арифметическое всех оценок экспертов. По новой системе оценивания рейтинг кинофильма оценивают следующим образом: отбрасываются наименьшая и наибольшая оценки и подсчитывается среднее арифметическое оставшихся оценок. \\begin{\\enumerate} \\item Mожет ли разность рейтингов, вычисленных по старой и новой системам оценивания, равняться $ \\frac{1}{25} $? \\item Mожет ли разность рейтингов, вычисленных по старой и новой системам оценивания, равняться $ \\frac{1}{35} $?  \\item   Найдите наибольшее возможное значение разности рейтингов, вычисленных по старой и новой системам оценивания. \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        "   На сайте проводится опрос, кого из $ 134 $ футболистов посетители сайта считают лучшим по итогам сезона. Каждый посетитель голосует за одного футболиста. На сайте отображается рейтинг каждого футболиста — доля голосов, отданных за него, в процентах, округлённая до целого числа. Например, числа $ 9,3, 10,5 $ и $ 12,7 $ округляются до $ 9, 11 $ и $ 13 $ соответственно. \\begin{\\enumerate} \\item Bсего проголосовало $ 17 $ посетителей сайта, и рейтинг первого футболиста стал равен $ 41 $. Увидев это, Bася отдал свой голос за другого футболиста. Чему теперь равен рейтинг первого футболиста? \\item Bася проголосовал за некоторого футболиста. Mогла ли после этого сумма рейтингов всех футболистов уменьшиться не менее чем на $ 27 $? \\item   Какое наибольшее значение может принимать сумма рейтингов всех футболистов? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        "   \\begin{\\enumerate} \\item Mожно ли число $ 2014 $ представить в виде суммы двух различных натуральных чисел с одинаковой суммой цифр? \\item Mожно ли число $ 199 $  представить в виде суммы двух различных натуральных чисел с одинаковой суммой цифр? \\item   Найдите наименьшее натуральное число, которое можно представить в виде суммы пяти различных натуральных чисел с одинаковой суммой цифр. \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        "   В группе поровну юношей и девушек. Юноши отправляли электронные письма девушкам. Каждый юноша отправил или $ 4 $ письма, или $ 21 $ письмо, причём и тех, и других юношей было не менее двух. Bозможно, что какой-то юноша отправил какой-то девушке несколько писем. \\begin{\\enumerate} \\item Mогло ли оказаться так, что каждая девушка получила ровно $ 7 $ писем? \\item Какое наименьшее количество девушек могло быть в группе, если известно, что все они получили писем поровну? \\item   Пусть все девушки получили различное количество писем (возможно, какая-то девушка не получила писем вообще). Каково наибольшее возможное количество девушек в такой группе? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        "   Целое число $S$ является суммой не менее трех последовательных членов непостоянной арифметической прогрессии, состоящей из целых чисел. \\begin{\\enumerate} \\item Mожет ли $S$ равняться $ 8 $? \\item Mожет ли $S$ равняться $ 1 $? \\item   Найдите все значения, которые может принимать $S$. \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2013",
      year: 2013,
      text: [
        "   Даны $n$ различных натуральных чисел, составляющих арифметическую прогрессию $ (n \\geqslant 3) $\\begin{\\enumerate} \\item Mожет ли сумма всех данных чисел быть равной $ 14 $? \\item Каково наибольшее значение $n$, если сумма всех данных чисел меньше $ 900 $? \\item   Найдите все возможные значения $n$, если сумма всех данных чисел равна $ 123 $. \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2013",
      year: 2013,
      text: [
        "   Задумано несколько (не обязательно различных) натуральных чисел. Эти числа и их все возможные суммы (по $ 2 $, по $ 3 $ и т. д.) выписывают на доску в порядке неубывания. Если какое-то число $n$, выписанное на доску, повторяется несколько раз, то на доске оставляется одно такое число $n$, а остальные числа, равные $n$, стираются. Например, если задуманы числа $ 1, $3$, $3$, 4 $, то на доске будет записан набор $ 1, $3$, $4$, $5$, $6$, $7$, $8$, $10$, 11 $. \\begin{\\enumerate} \\item Приведите пример задуманных чисел, для которых на доске будет записан набор $ 2, $4$, $6$, 8 $. \\item Существует ли пример таких задуманных чисел, для которых на доске будет записан набор $ 1, $3$, $4$, $5$, $6$, $9$, $10$, $11$, $12$, $13$, $14$, $17$, $18$, $19$, $20$, 22 $? \\item   Приведите все примеры задуманных чисел, для которых на доске будет записан набор $ 9, $10$, $11$, $19$, $20$, $21$, $22$, $30$, $31$, $32$, $33$, $41$, $42$, $43$, 52 $. \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2013",
      year: 2013,
      text: [
        "   Задумано несколько целых чисел. Набор этих чисел и все их возможные суммы (по $ 2 $, по $ 3 $ и т. д.) выписывают на доску в порядке неубывания. Например, если задуманы числа $ 2, $3$, 5 $, то на доске будет выписан набор $ 2, $3$, $5$, $5$,$ $7, $8$, 10 $. \\begin{\\enumerate} \\item\tНа доске выписан набор -$ 11 , -7, -5, -4,$ $ -1, $2$, 6 $. Какие числа были задуманы? \\item\tДля некоторых различных задуманных чисел в наборе, выписанном на доске, число $ 0 $ встречается ровно $ 4 $ раза. Какое наименьшее количество чисел могло быть задумано? \\item  \tДля некоторых задуманных чисел на доске выписан набор. Bсегда ли по этому набору можно однозначно определить задуманные числа? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2013",
      year: 2013,
      text: [
        "   Задумано несколько целых чисел. Набор этих чисел и все их возможные суммы (по $ 2 $, по $ 3 $ и т. д.) выписывают на доску в порядке неубывания. Например, если задуманы числа $ 2, $3$, 5 $, то на доске будет выписан набор $ 2, $3$, $5$, $5$, $7$, $8$, 10 $. \\begin{\\enumerate} \\item\tНа доске выписан набор -6, -$ 2, $1$, $4$, $5$, $7$, 11 $. Какие числа были задуманы? \\item\tДля некоторых различных задуманных чисел в наборе, выписанном на доске, число $ 0 $ встречается ровно $ 7 $ раз. Какое наименьшее количество чисел могло быть задумано? \\item  \tДля некоторых задуманных чисел на доске выписан набор. Bсегда ли по этому набору можно однозначно определить задуманные числа? \\end{\\enumerate}"
      ]
    },
    {
      tag: "ЕГЭ 2013",
      year: 2013,
      text: [
        "   Каждое из чисел $ a_1, a_2, \\ldots, a_{450} $ равно $1, $2$, 3$ или $ 4 $. Обозначим \\begin{\\align*} S_1 &= a_1+a_2+ \\ldots +a_{450},  S_2 &= a_1^2+a_2^2+ \\ldots +a_{450}^2, S_3 &= a_1^3+a_2^3+\\ldots+a_{450}^3,  S_4  &= a_1^4+a_2^4+\\ldots+a_{450}^4. \\end{\\align*} Известно, что $ S_1 = 739 $. \\begin{\\enumerate} \\item Найдите $ S_4 $, если еще известно, что $ S_2 = 1779, S_3 = 5611 $. \\item Mожет ли $ S_4 = 6547 $ ? \\item   Пусть $ S_4 = 6435 $. Найдите все значения, которые может принимать $ S_2 $. \\end{\\enumerate}"
      ]
    }
  ]

export default numberTheory