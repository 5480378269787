  const parameters =  [
    {
      tag: "ЕГЭ 2020",
      year: 2020,
      text: [
        "  Hайдите все значения параметра $ a$, при которых система   $  \\begin{cases} \\log_{11} \\left(16 - y^2 \\right) = \\log_{11} \\left(16 - a^2 x^2 \\right) ; \\\\x^2 + y^2 =2x + 4y \\end{cases} $ имеет ровно $ 2 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2020",
      year: 2020,
      text: [
        "  При каких значениях параметра $ a $ система    $ \\begin{cases}\\sqrt{ 16 - y^2 }=\\sqrt{ 16 - a^2 x^2 }; \\\\x^2 + y^2 =6x + 4y \\end{cases} $ имеет ровно $ 2 $ решения?"
      ]
    },
    {
      tag: "ЕГЭ 2020",
      year: 2020,
      text: [
        "  Hайдите все значения параметра  $ a$, при каждом из которых система  $  \\begin{cases} x^2 + y^2 =4 + 2ax - a^2 ; \\\\x^2 =y^2 \\end{cases} $  имеет ровно $4$ решения."
      ]
    },
    {
      tag: "ЕГЭ 2020",
      year: 2020,
      text: [
        "  Hайдите все значения параметра  $ a$, при каждом из которых система  $  \\begin{cases} x^2 + y^2 =2x + 2y; \\\\ x^2 + y^2 =2(1 + a)x + 2(1 - a)y - 2a^2 \\end{cases} $.  имеет ровно $ 2 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2019",
      year: 2019,
      text: [
        "  Hайдите все значения параметра $ a$, при каждом из которых наименьшее значение функции   $  f(x)=ax - a - 1 + |x^2 - 4x + 3| $ меньше $ -2 $."
      ]
    },
    {
      tag: "ЕГЭ 2019",
      year: 2019,
      text: [
        "  Hайдите все значения параметра $ a$, при каждом из которых уравнение   $ 2 \\sin x + \\cos x=a$  имеет единственное решение на отрезке   $ \\left[ \\frac{\\pi }{4 }; \\frac{3 \\pi }{4 }\\right] $. "
      ]
    },
    {
      tag: "ЕГЭ 2019",
      year: 2019,
      text: [
        "  При каких значениях параметра $ a$  уравнение   $  \\frac{|4x| - x - 3 - a}{x^2 - x - a} =0 $ имеет ровно $2$ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2019",
      year: 2019,
      text: [
        "  Hайдите все значения параметра $ a$, при каждом из которых уравнение   $ 3 \\sin x + \\cos x=a $ имеет ровно один корень на отрезке  $ \\left[ \\frac{\\pi }{4 }; \\frac{3 \\pi}{4 }\\right] $. "
      ]
    },
    {
      tag: "ЕГЭ 2018",
      year: 2018,
      text: [
        "  Hайти все значения параметра $ a$, при каждом из которых уравнение   $  \\sqrt{ x + 2a - 1} +\\sqrt{ x - a} = 1 $ имеет хотя бы один корень."
      ]
    },
    {
      tag: "ЕГЭ 2018",
      year: 2018,
      text: [
        "  Hайти все значения параметра $ a$, при каждом из которых система уравнений   $  \\begin{cases} x^2 + y^2 + 2(a - 3)x - 4ay + 5a^2 - 6a=0; \\\\ y^2 =x^2 \\end{cases} $ имеет ровно $ 4 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2018",
      year: 2018,
      text: [
        "  Hайти все значения параметра $ a$, при каждом из которых система уравнений   $  \\begin{cases} (x + ay - 5)(x + ay - 5a)=0; \\\\ x^2 + y^2 =16 \\end{cases} $ имеет ровно $ 4 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2018",
      year: 2018,
      text: [
        "  Hайти все значения параметра $ a$, при каждом из которых система уравнений   $  \\begin{cases} (x + ay - 4)(x + ay - 4a)=0; \\\\ x^2 + y^2 =9 \\end{cases} $ имеет ровно $ 4 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2018",
      year: 2018,
      text: [
        "  Hайдите все значения параметра $ a $, при каждом из которых система уравнений   $  \\begin{cases}  y=(a + 2)x^2 + 2ax + a - 2; \\\\ y^2 =x^2 . \\end{cases} $  имеет ровно $ 4 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2017",
      year: 2017,
      text: [
        "  Hайдите все значения параметра $ a $, при каждом из которых уравнение    $  x^2 + (x - 1) \\cdot \\sqrt{ 3x - a}=x $имеет ровно один корень на отрезке $ [0; 1] $."
      ]
    },
    {
      tag: "ЕГЭ 2017",
      year: 2017,
      text: [
        "  Hайдите все значения параметра $ a $, при каждом из которых уравнение   $ x^2 + (x - 1) \\cdot \\sqrt{ 2x - a}=x $ имеет ровно один корень на отрезке $ [0; 1] $."
      ]
    },
    {
      tag: "ЕГЭ 2017",
      year: 2017,
      text: [
        "  Hайти все значения параметра $ a$, при каждом из которых уравнение  $ \\sqrt{ 4x - 1}\\ln(x^2 - 2x + 2 - a^2 )=0 $ имеет ровно один корень на отрезке $ [0; 1] $."
      ]
    },
    {
      tag: "ЕГЭ 2017",
      year: 2017,
      text: [
        "  Hайдите все значения параметра $ a$, при каждом из которых уравнение   $ x \\sqrt{ x - a}=\\sqrt{ 6x^2 - (6a + 3)x + 3a} $ имеет ровно один корень на отрезке $ [0; 1] $."
      ]
    },
    {
      tag: "ЕГЭ 2016",
      year: 2016,
      text: [
        "  Hайдите все значения параметра $ a $, при каждом из которых уравнение   $  \\sqrt{ x^4 - x^2 + a^2 }=x^2 + x - a $ имеет ровно $ 3 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2016",
      year: 2016,
      text: [
        "  Hайдите все значения параметра $ a$, при каждом из которых уравнение $ 2^x - a=\\sqrt{ 4^x - a} $ имеет единственный корень."
      ]
    },
    {
      tag: "ЕГЭ 2016",
      year: 2016,
      text: [
        "  Hайдите  все значения параметра $ a $, при каждом из которых система уравнений   $  \\begin{cases} x(x^2 + y^2 - y - 2)=|x|(y - 2); \\\\y=x + a \\end{cases} $ имеет ровно $ 3 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2016",
      year: 2016,
      text: [
        "  Hайдите  все значения параметра $ a $, при каждом из которых система уравнений   $  \\begin{cases} (xy^2 - xy - 6y + 6)\\sqrt{ y + 2}=0; \\\\ y=ax \\end{cases} $ имеет ровно $ 3 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2016",
      year: 2016,
      text: [
        "  Hайдите все значения параметра $ a $, при каждом из которых система уравнений    $ \\begin{cases} (xy^2 - 2xy - 6y + 12)\\sqrt{ 6 - x}=0; \\\\ y=ax \\end{cases} $ имеет ровно $ 3 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2015",
      year: 2015,
      text: [
        "  Hайдите все значений $ a$, при каждом из которых система уравнений   $  \\begin{cases} (y^2 - xy + x - 3y + 2)\\sqrt{ x + 3}=0; \\\\ a - x - y=0 \\end{cases} $ имеет ровно $ 2 $ различных решения."
      ]
    },
    {
      tag: "ЕГЭ 2015",
      year: 2015,
      text: [
        "  Hайдите все значения параметра $ a$, при каждом из которых система уравнений   $  \\begin{cases} \\frac{(y^2 - xy - 4y + 2x + 4)\\sqrt{ x + 4}}{\\sqrt{ 5 - y }}=0; \\\\ a=x + y \\end{cases} $ имеет единственное решение."
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        " Hайдите все значения параметра $ a$, при которых уравнение  $  \\left((a - 2)x^2 + 6x \\right)^2 - 4 \\left((a - 2)x^2 + 6x \\right) + 4 - a^2 =0 $имеет ровно $ 2 $ решения."
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        " Hайдите все значения параметра $ a$, при которых уравнение  $ \\sqrt{ x^4 + (a - 2)^4 }=|x + a - 2| + |x - a + 2| $ имеет единственное решение."
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        " Hайдите все значения параметра $ a$,   при которых уравнение  $ ( \\log_8 (x + a) - \\log_8 (x - a))^2 - 12a( \\log_8 (x + a) - \\log_8 (x - a)) + 35a^2 - 6a - 9=0 $имеет ровно $ 2 $ решения."
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        " Hайдите все значения параметра $ a$, при которых уравнение  $ (|x + 7| - |x - a|)^2 - 13a(|x + 7| - |x - a|) + 30a^2 + 21a - 9=0 $имеет ровно $ 2 $ решения."
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        " Hайдите все значения параметра $ a$,   при которых уравнение  $ ( \\log_2 (x + a) - \\log_2 (x - a))^2 - 3a( \\log_2 (x + a) - \\log_2 (x - a)) + 2a^2 - a - 1=0 $ имеет ровно $ 2 $ решения."
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        " Hайдите все значения параметра  $a$,   при которых уравнение  $  \\sin^{14} x + (a - 3 \\sin x)^7 + \\sin^2 x + a=3 \\sin x $имеет хотя бы $ 1 $ решение."
      ]
    },
    {
      tag: "ЕГЭ 2014",
      year: 2014,
      text: [
        " Hайдите все значения параметра $ a$, при которых уравнение  $  \\left(x + \\frac{1}{x - a }\\right)^2 - (a + 9) \\left(x + \\frac{1}{x - a }\\right) + 2a(9 - a)=0 $ имеет ровно $4$ решения."
      ]
    },
    {
      tag: "ЕГЭ 2013",
      year: 2013,
      text: [
        " Hайдите все значения параметра $ a$, для каждого из которых уравнение    $ \\log_{1 - x} (a - x + 2)=2 $ имеет хотя бы один корень; принадлежащий промежутку $ [-1; 1) $."
      ]
    },
    {
      tag: "ЕГЭ 2013",
      year: 2013,
      text: [
        " Hайдите все значения параметра $ a$, при каждом из которых уравнение  $ ax +\\sqrt{ - 7 - 8x - x^2 }=2a + 3 $  имеет единственный корень."
      ]
    },
    {
      tag: "ЕГЭ 2013",
      year: 2013,
      text: [
        " Hайдите все значения а, при каждом из которых уравнение  $ x^2 - |x + 3 + a| = |x - a - 3| - (a + 3)^2  $имеет единственный корень."
      ]
    }
  ]

export default parameters