const DVI = [
  {
    tag: 'ДВИ МГУ 2011',
    year: 2011,
    text: [
      ' Вычислите значение функции $ \\frac{x^2 - 1,75}{x+5} $ в точке $ x=\\frac{4}{3} $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2011',
    year: 2011,
    text: [' Решите уравнение $ (\\sin x - \\cos x)^2 = 2 $.']
  },
  {
    tag: 'ДВИ МГУ 2011',
    year: 2011,
    text: [' Решите уравнение $ \\log_3(2x+1)=\\log_9(4+3x) $.']
  },
  {
    tag: 'ДВИ МГУ 2011',
    year: 2011,
    text: [' Решите неравнество $ \\frac{\\sqrt{1-3x}-1}{\\sqrt{2+x}-1} <1. $']
  },
  {
    tag: 'ДВИ МГУ 2011',
    year: 2011,
    text: [
      ' Медианы $ PE $ и $ QF $ треугольника $ PQR $ пересекаются в точке $ S $. Найди­те длину отрезка $ PQ $, если $ SR=2 $ и известно, что вокруг четырехугольника $ SERF $ можно описать окружность.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2011',
    year: 2011,
    text: [
      ' Найдите наибольшее из значений функции $ \\frac{10^x}{25^{x-1} + 10^x + 4^{x+1}}. $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2011',
    year: 2011,
    text: [
      ' В кубе с ребром $ 1 $ расположены две сферы различных радиусов. Первая касается плоскости основания и двух соседних боковых граней куба. Вторая сфера касается двух других боковых граней куба, грани куба, параллельной основанию, и первого шара. Чему равна сумма радиусов сфер?'
    ]
  },
  {
    tag: 'ДВИ МГУ 2011',
    year: 2011,
    text: [
      ' Решите систему неравенств $ \\begin{cases}5x^2-2xy+9y^2 \\leqslant 1,\\\\ 3x-5y \\leqslant -2.\\end{cases} $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2012',
    year: 2012,
    text: [
      ' Найдите многочлен второй степени, если известно, что его корни равны $ -\\frac{5}{7} $ и $ \\frac{9}{4} $, а свободный член равен $ -5 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2012',
    year: 2012,
    text: [' Вычислите $ \\log_3 \\log_{64} \\frac{716}{179}$.']
  },
  {
    tag: 'ДВИ МГУ 2012',
    year: 2012,
    text: [
      ' Решите неравенство $ \\left( 4^x - 7 \\cdot 2^x + 12 \\right) \\cdot \\sqrt{3^{x+1} - 1} \\leqslant $ $ 0. $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2012',
    year: 2012,
    text: [' Решите уравнение $ \\cos 3x = \\cos x +\\sqrt3 \\sin x. $']
  },
  {
    tag: 'ДВИ МГУ 2012',
    year: 2012,
    text: [
      ' Найдите площадь фигуры, состоящей из точек $ (x, y) $ координатной плоскости, удовлетворяющих уравнению $ |2y - x| + 2|y+4|+|x|=8. $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2012',
    year: 2012,
    text: [
      '  Окружность касается сторон $ AB $ и $ BC $ треугольника $ ABC $ в точках $ K $ и $ L $, соответственно, и пересекает сторону $ AC $ в точках $ M $, $ N $ (точка $ M $ лежит между точками $ A $ и $ N $). Найдите радиус этой окружности, если известно, что $ AM = 1 , NC = 3 , AK:KB = 2 :1 $ и $ BL:LC = 1:4 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2012',
    year: 2012,
    text: [
      ' Определите, при каких значениях параметра $ a $ уравнение $ a\\sqrt{x+y}=\\sqrt{2x}+\\sqrt{3y} $ имеет единственное решение $ (x, y) $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2012',
    year: 2012,
    text: [
      ' В основании пирамиды лежит правильный треугольник $ ABC $ со сто­роной $ 5 $, боковые ребра $ AS $, $ BS $, $ CS $ пирамиды равны соответственно $ 7 $, $ 7 $ и $ 3 $. Прямой круговой цилиндр расположен так, что окружность его верхнего основания имеет ровно одну общую точку с каждой из боковых граней пира­миды, а окружность нижнего основания лежит в плоскости $ ABC $ и касается прямых $ AC $ и $ BC $. Найдите радиус основания цилиндра.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2013',
    year: 2013,
    text: [
      ' Старший коэффициент квадратного трехчлена $ f(x) $ равен $ -3 $. Один из его корней равен $ \\frac{7}{3} $.Найдите второй корень, если известно, что $ f(0)=4 $. Ответ: $ -\\frac{4}{7} $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2013',
    year: 2013,
    text: [
      ' Вычислите $ \\log_{16} 6 \\cdot \\log_6 8 $. Ответ: $ \\frac{3}{4} $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2013',
    year: 2013,
    text: [
      ' Решите неравенство $ 12 \\left( 3+3^{-2x} \\right)^{-\\frac{1}{2}} - \\left( 3^{1+2x} + 1 \\right)^{\\frac{1}{2}} \\geqslant $ $ 4 \\cdot 3^{\\frac{x}{2}} $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2013',
    year: 2013,
    text: [
      ' Решите уравнение $ \\frac{\\cos4x}{\\sin3x}-\\frac{\\sin4x}{\\cos3x}=\\frac{\\sin3x}{\\cos4x}-\\frac{\\cos3x}{\\sin4x} $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2013',
    year: 2013,
    text: [
      ' От биостанции до границы заповедника вниз по реке ровно $ 8 $ км. В $ 8 : 00 $ браконьеры вошли на катере в заповедник и направились в сторону биостанции. В это же время им навстре­чу с биостанции вышел катер с рыбинспекторами. Через $ 6 $ минут, когда рыбинспекторы были ровно посередине между биостанцией и браконьерами, браконьеры заметили катер рыбинспекции, тут же развернулись и направились обратно к границе заповедника. Когда браконьеры достигли границы, рыбинспекторы с чувством выполненного долга разверну­лись и отправились обратно на биостанцию, куда прибыли в $ 08 : 25 $. Найдите наименьшее расстояние, на котором находились браконьеры от рыбинспекторов, если известно, что оба катера движутся равномерно и с одинаковой собственной скоростью. Ответ: 3 км.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2013',
    year: 2013,
    text: [
      ' Трапеция $ KLMN $ вписана в окружность радиуса $ R $ и описана около окружности радиуса $ r $, причем $ R=\\frac{3}{2}r $. Найдите среднюю линию трапеции, если диагональ $ KM $ равна $ 3 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2013',
    year: 2013,
    text: [
      " В основании прямой призмы $ KLMK'L'M' $ лежит прямоугольный треугольник $ KLM $ , такой что $ KM=LM=1 $. На ребре $ K'M' $ верхнего основания (параллельном $ KM $) отмечена точка $ N $, так что $ K'N : NM' = 3 : 1 $. Найдите радиус сферы, вписанной в тетраэдр $ KL'MN $ eсли высота призмы равна $ 1 $. Ответ: $ \\left( 1 + \\frac{7 + \\sqrt{13}}{2\\sqrt2}\\right)^{-1} $."
    ]
  },
  {
    tag: 'ДВИ МГУ 2013',
    year: 2013,
    text: [
      ' Найдите все значения параметра $ a $, при которых уравнение $ \\cos \\left( x + a \\ln|x| \\right) = x-1 $ имеет бесконечно много решений. Ответ: $ a \\ne 0 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2014',
    year: 2014,
    text: [
      ' Найдите в явном виде натуральное число, заданное выражением $ \\sqrt{7+2\\sqrt{10}}\\left( \\sqrt{5}-\\sqrt{2} \\right) $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2014',
    year: 2014,
    text: [
      ' Найдите максимальное значение функции $ \\log_{\\frac{1}{3}} (x^2+10x+34) $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2014',
    year: 2014,
    text: [
      ' Найдите все положительные $ x $, удовлетворяющие неравенству $ x^{-7x+5} < x^{-4} $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2014',
    year: 2014,
    text: [
      ' Решите уравнение $ \\cos^2 x + \\sqrt{2} |\\cos x| \\sin \\left( \\frac{5x}{2} - \\frac{\\pi}{8} \\right) + \\frac{1}{2} = 0 $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2014',
    year: 2014,
    text: [
      ' Окружности $ \\Omega_1 $ и $ \\Omega_2 $ с центрами в точках $ O_1 $ и $ O_2 $ касаются внешним образом в точке $ А $. Общая внешняя касательная к этим окружностям касается $ \\Omega_1 $ в точке $ B $ и пересекает в точке $ C $ общую касательную этих окружностей, проходящую через точку $ A $. Прямая,  делящая угол $ ACO_1 $ пополам, пересекает прямые $ O_1 O_2 $ и $ B O_1 $ в точках $ L $ и $ D $ соответственно. Найдите $ LO_1 $, если известно, что $ CO_2 = 2 $, а прямые $ CO_2 $ и $ DO_2 $ перпендикулярны. Ответ: 1.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2014',
    year: 2014,
    text: [
      ' Найдите все $ (x, y) $ на интервале $ \\left( 0, \\frac{\\pi}{2} \\right) $, удовлетворяющие системе уравнений $ \\begin{cases}\\frac{1}{\\sin^3} + \\frac{1}{\\cos^3} = 54 \\\\\\ctg^2 x + \\tg^2 y = 16\\end{cases} $ Ответ: $ \\begin{cases}x = \\arcsin \\frac{1}{3} \\\\ y = \\arccos \\frac{1}{3}\\end{cases} $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2014',
    year: 2014,
    text: [
      ' В основании прямой призмы лежит квадрат со стороной $ 1 $. Высота призмы равна $ \\sqrt3 $. Найдитерасстояние между большой диагональю призмы и скрещивающейся с ней диагональю боковой грани. Ответ: $ \\frac{\\sqrt3}{4} $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2014',
    year: 2014,
    text: [
      ' Пусть $ \\begin{cases}f(x, y) &= \\sqrt{-6x^2-11y^2-16xy+5}+y, \\\\g(x, y) &= -\\sqrt{-6x^2-11y^2-16xy+5}+y.\\end{cases} $ Найдите все значения, которые может принимать хотя бы одна из этих функций.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2015',
    year: 2015,
    text: [
      ' Найдите $ f(3) $, если $ f(x) = \\frac{x}{7} + \\frac{2}{x} - \\frac{2}{21} $. %Ответ: 1'
    ]
  },
  {
    tag: 'ДВИ МГУ 2015',
    year: 2015,
    text: [
      ' Найдите сумму квадратов корней уравнения $ x^2 + 10x + 4 = 0 $. %Ответ: 92'
    ]
  },
  {
    tag: 'ДВИ МГУ 2015',
    year: 2015,
    text: [
      ' Решите неравенство $ \\cos x -\\sqrt{\\frac{2}{3}} \\cos 2x - \\sin x \\geqslant $ $ 0 $. \\\\ %Ответ: $ \\left[ -\\frac{3\\pi}{4}+ 2n\\pi, \\frac{\\pi}{12}+ 2n\\pi \\right] \\cup \\left[ \\frac{\\pi}{4}+ 2n\\pi, \\frac{5\\pi}{12} + 2n\\pi \\right], n\\in \\mathbb{Z}$'
    ]
  },
  {
    tag: 'ДВИ МГУ 2015',
    year: 2015,
    text: [
      ' Решите уравнение $ \\log_{\\sqrt{x+1}} |5x-1| = 4\\log_{|5x-1|} \\sqrt{x+1} $. %Ответ: $ x = -\\frac{4}{5}, \\frac{1}{2}, \\frac{-2+\\sqrt{14}}{5} $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2015',
    year: 2015,
    text: [
      ' Окружность касается середины стороны $ AC $ треугольника $ ABC $ и пересекает сторону $ BC $ в точках $ K $ и $ L $, так что $ BK=KL=LC $. Чему может равняться радиус окружности, если $ \\angle ABC = 45^\\circ $ и $ AB = 1 $? %Ответ: $ 3 \\pm \\sqrt7 $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2015',
    year: 2015,
    text: [
      ' Лыжник Григорий ехал по довольно пологому склону, но, проехав три четверти пути, проявил неуклюжесть и сломал лыжи. %Отбросив их за ненадобностью, он тут же побрёл обратно. В момент поломки с вершины горы стартовал лыжник Василий. На каком расстоянии от вершины он встретит Григория, если длина трассы равна $ 2100 $ метров, а Василий закончит спуск ровно тогда, когда. Григорий доберётся до вершины горы? Скорости лыжников и пешехода считать постоянными. %Ответ: 900 м.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2015',
    year: 2015,
    text: [
      " В правильную треугольную призму с основаниями $ ABC $, $ A'B'C' $ и рёбрами $ AA' $, $ BB' $, $ CC' $ вписана сфера. Найдите её радиус, если известно, что расстояние между прямыми $ A'K $ и $ B'L $ равно $ \\sqrt{21} $ , где $ K $ и $ L $ — точки, лежащие на $ AB $ и $ BC $ соответственно, и $ AK : KB = BL : LC = 2 : 3 $."
    ]
  },
  {
    tag: 'ДВИ МГУ 2015',
    year: 2015,
    text: [
      ' Найдите все пары $ (x, y) $, при которых достигается минимум выражения $ \\frac{2-\\sin x}{2+\\cos 2x} + \\frac{2+\\cos2x}{(y+1)^2} + \\frac{(y+1)^2}{2\\sqrt{y}+1} + \\frac{2\\sqrt{y}+1}{2-\\sin x}. $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2016',
    year: 2016,
    text: [
      ' Найдите $ f\\left( \\frac{5}{3} \\right) $, если $ f(x) = \\frac{x}{x-1} - \\frac{4}{9} $. Ответ: $ \\frac{37}{18} $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2016',
    year: 2016,
    text: [
      ' Разность между наибольшим и наименьшим корнями уравнения $ x^2 + ax + 10 = 0 $ равна $ 3 $. Найдите все возможные значения $ a $. Ответ: $ \\pm7 $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2016',
    year: 2016,
    text: [
      ' Решите уравнение $ 2 \\cos 2x + 3 \\sin 2x + 4 \\cos^2 x = -1 $. Ответ: $ x = -\\frac{\\pi}{4} + k\\pi, \\arctg7 + k\\pi, k \\in \\mathbb{Z} $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2016',
    year: 2016,
    text: [
      ' Решите неравенство $ \\log_{1+\\log_x 7}\\left( 1+\\log^2_7 x \\right) \\leqslant $ $ 1 $. Ответ: $ \\left( 0, \\frac{1}{7} \\right) \\cup (1, 7] $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2016',
    year: 2016,
    text: [
      ' Две окружности касаются внутренним образом в точке $ P $. Хорда $ QR $ внешней окружности касается внутренней окружности в точке $ S $. Прямая $ PS $ пересекает внешнюю окружность в точках $ P $ и $ T $. Найдите $ QT $, если известно, что $ PQ || RT $, площадь четырёхугольника $ PQTR $ равна $ 5\\sqrt5 $, а радиусы окружностей относятся как $ 7 : 10 $. Ответ: 3'
    ]
  },
  {
    tag: 'ДВИ МГУ 2016',
    year: 2016,
    text: [
      ' Ровно в $ 13:00 $ из пункта А в пункт Б выехал мотоциклист. Проехав четверть пути, наблю­дательный мотоциклист заметил, что мимо него в сторону пункта А прошёл некий пешеход.В тот самый момент, когда мотоциклист прибыл в пункт Б, из пункта Б в пункт А выехал автомобиль. Когда до пункта А оставалось пятая часть пути, не менее наблюдательный во­дитель автомобиля заметил, что он поравнялся с тем самым пешеходом. Во сколько приехал автомобиль в пункт А, если известно, что пешеход прибыл в пункт А ровно в $ 17:00 $? Скорости пешехода, мотоцикла и автомобиля считать постоянными.Ответ: В $ 14:00 $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2016',
    year: 2016,
    text: [
      ' В основании правильной пирамиды с вершиной $ V $ лежит шестиугольник $ KLMNOP $ со стороной $ 10 $. Плоскость $ \\pi $ параллельна ребру $ LM $, перпендикулярна плоскости $ OPV $ и пересекает ребро $ MN $ в точке $ T $, так что $ MT : TN = 1 : 4 $. Кроме того, прямые по которым $ \\pi $ пересекает плоскости $ MNV $ и плоскость основания, перпендикулярны. Найдите площадь треугольника, отсекаемого плоскостью $ \\pi $ от грани $ NOV $. Ответ: $ 9\\sqrt2 $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2016',
    year: 2016,
    text: [
      ' Найдите наименьшее значение выражения $ \\sqrt{65 + \\log^2_a \\cos ax - \\log_a \\cos^8 ax} + \\sqrt{10 + \\log^2_a \\sin ax + \\log_a \\sin^2 ax} + \\sqrt{125 + \\log^2_a \\tg ax - \\log_a \\tg^{10} ax}$ и все пары $ (a, x) $, при которых оно достигается. Ответ: $ 10\\sqrt5, a = \\frac{1}{2}, x = \\frac{\\pi}{2} + 4k\\pi, k \\in \\mathbb{Z} $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2017',
    year: 2017,
    text: [
      ' Какое число больше: $ \\sqrt{\\frac{8}{9} + 7 + \\frac{9}{8}} $ или $ 3 $?'
    ]
  },
  {
    tag: 'ДВИ МГУ 2017',
    year: 2017,
    text: [
      ' Известно, что $ a+b+c=7 $ и $ a^2+b^2+c^2 =19 $. Найдите  $ ab+bc+ac $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2017',
    year: 2017,
    text: [' Решите уравнение $ \\cos 8x - \\cos 9x = \\sin x $.']
  },
  {
    tag: 'ДВИ МГУ 2017',
    year: 2017,
    text: [
      ' Решите неравенство $ x^2 \\log^2_4 x + 10 \\log^2_3 x \\leqslant $ $ x\\log_4 x \\cdot \\log_3 x^7 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2017',
    year: 2017,
    text: [
      ' Через вершины $ M $ и $ K $ треугольнка $ KLM $ проведена окружность, касающаяся прямых $ ML $ и $ KL $. На этой окружности выбрана точка $ S $ (внутри треугольника), лежащая на расстоянии на расстоянии $ \\sqrt2 $ от прямой $ MK $. Найдите расстояние от точки $ S $ до прямой $ KL $, если известно, что $ \\angle MKS = \\angle KLS $ и что $ \\angle SKL = 60^\\circ $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2017',
    year: 2017,
    text: [
      ' Борис с друзьями решили устроить пикник. Для этого им от пункта $ A $ нужно добраться вверх по реке до пункта $ B $, причем, в их распоряжении есть два катера. Считая себя самым ответственным, Борис вызвался самостоятельно доехать до пункта $ B $ на более быстроходном катере и начать готовить место для пикника. Оба катера вышли одновременно из пункта $ A $.Однако, промчавшись $ 10 $ км, Борис заметил на берегу машущего ему рукой Анатолия, который просил по старой дружбе довезти его до пункта $ C $. И хоть пункт $ C $ Борис уже проехал, он согласился. По пути в пункт $ C $ Борис с Анатолием встретили идущий навстречу второй катер с друзьями Бориса, откуда те крикнули, что пункт $ B $ уже совсем близко и чтобы Борис нигде не задерживался. Доставив Анатолия в пункт $ C $, Борис немедленно помчался догонять друзей.Определите, какую долю пути оставалось пройти друзьям Бориса от момента встречи с ним и Анатолием, если известно, что оба катера пришли в пункт $ B $ одновременно, расстояние между пунктами $ B $ и $ C $ равно $ 2 $ км, скорости катеров постоянны, а Борис, действительно, нигде не задерживался.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2017',
    year: 2017,
    text: [
      ' Из вершины $ S $ на плоскость $ KLM $ пирамиды $ KLMS $ опущена высота $ SH $. Найдите объём этой пирамиды, если известно, что площади треугольников $ \\triangle HLM $, $ \\triangle HKM $, $ \\triangle HKL $ равны соответственно $ \\frac{1}{4}, \\frac{1}{3}, \\frac{5}{12} $, и что все три плоских угла при вершине $ S $ прямые.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2017',
    year: 2017,
    text: [
      ' Решите систему уравнений $\\begin{cases}\\frac{x}{\\sin(x^2 - y^2)} - y \\cdot \\ctg(x^2 - y^2) = \\sqrt{\\frac{\\pi}{6}} \\\\\\frac{y}{\\sin(x^2 - y^2)} - x \\cdot \\ctg(x^2 - y^2) = \\sqrt{\\frac{\\pi}{2}}\\end{cases}. $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2018',
    year: 2018,
    text: [
      ' Какое из чисел $ \\frac{53}{36} $ и $ \\frac{68}{27} $ ближе к $ 2 $?'
    ]
  },
  {
    tag: 'ДВИ МГУ 2018',
    year: 2018,
    text: [
      '  Найдите все значения параметра $ p $, при которых разность между корнями уравнения $ x^2 + px + 5p^4 = 0 $ максимальна'
    ]
  },
  {
    tag: 'ДВИ МГУ 2018',
    year: 2018,
    text: [' Решите уравнение $ \\cos 12x \\cos 5x = \\cos 8x \\cos x $.']
  },
  {
    tag: 'ДВИ МГУ 2018',
    year: 2018,
    text: [
      ' Решите неравенство $ \\left(\\sqrt6 + \\sqrt6 \\right)^{\\log_{\\sqrt6-\\sqrt5} x} \\geqslant $ $ \\left(\\sqrt6-\\sqrt5\\right)^{\\log_x(\\sqrt6 + \\sqrt5} $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2018',
    year: 2018,
    text: [
      ' Дана трапеция $ ABCD $ с основаниями $ AD $ и $ BC $. Пусть $ M $ -- середина отрезка $ AD $, а $ N $ -- произвольная точка отрезка $ BC $. Пусть $ K $ -- пересечение отрезков $ CM $ и $ DN $, a $ L $ -- пересечение отрезков $ MN $ и $ AC $. Найдите все возможные значения площади треугольника $ ABL $, если известно, что $ AD : BC = 4 : 5 $, а площадь треугольника $ DMK $ равна $ 2 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2018',
    year: 2018,
    text: [
      ' Найдите все значения параметра $ p $, при которых система $ \\begin{cases*}px^2 + 8px + 12y + 18p - 30 \\geqslant 0  \\\\py^2 - 4py + 12x + 6p + 42 \\geqslant 0\\end{cases*} $ имеет ровно одно решение.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2018',
    year: 2018,
    text: [
      " Дан прямоугольный параллелепипед $ ABCDA'B'C'D' $ c боковыми рёбрами $ AA', BB', CC'$, $ DD' $. На рёбрах $ AB $, $ BC $, $ CD $, $ DA $ нижнего основания отмечены соответственно точки $ K $, $ L $, $ M $, $ N $, таким образом, что $ AK : KB = 9 : 7 $, $ BL : LC = 7 : 5 $, $ CM : MD = 5 : 3 $, $ DN : NA = 3 : 1 $. Пусть $ P $, $ Q $, $ R $ -- центры сфер, описанных около тетраэдров $ AKNA', BLKB', CMLC' $, соответственно. Найдите $ QR $, если известно, что $ PQ = 1 $ и $ AB : BC = 4 : 3 $."
    ]
  },
  {
    tag: 'ДВИ МГУ 2018',
    year: 2018,
    text: [
      ' Найдите все пары чисел $ (x, y) $ из промежутка $ \\left( 0, \\frac{\\pi}{2} \\right) $, при которых достигается минимум выражения $ \\left( \\frac{\\sqrt7 \\cos y}{\\sqrt6 \\sin (x+y)} + 1 \\right) \\left( \\frac{\\sqrt6\\cos x}{3 \\cos y} + 1 \\right)^2 \\left( \\frac{\\sin(x+y)}{7\\sqrt7 \\cos x} + 1 \\right)^4 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2019',
    year: 2019,
    text: [
      '\tНайдите наибольшее целое число, не превосходящее $ \\sqrt{2019 \\cdot 2007 - 2006 \\cdot 2020} $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2019',
    year: 2019,
    text: [
      '\tНайдите $ a + b + c $, если известно, что $ a + 3b = 1 $, $ b + Зc = 2 $, $ c + Зa = 5 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2019',
    year: 2019,
    text: ['\tРешите уравнение $ 5 \\sin x + 7 \\cos 2x = 6 $.']
  },
  {
    tag: 'ДВИ МГУ 2019',
    year: 2019,
    text: ['\tРешите неравенство $ 5^{\\log^2_5 x} + 3x^{\\log_5 x} < 20 $.']
  },
  {
    tag: 'ДВИ МГУ 2019',
    year: 2019,
    text: [
      '\tНа гипотенузе $ AB $ прямоугольного треугольника $ ABC $ отмечены точки $ D $ и $ E $ таким образом, что $ AD : DB = BE : EA =1:7 $. Найдите $ AB $, если известно, что площадь треугольника $ ABC $ равна $ 56 $, а тангенс угла $ \\angle DCE $ равен $ 3 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2019',
    year: 2019,
    text: [
      '\tНайдите все пары вещественных чисел $ (a, b) $, при которых неравенство $ 5a(x + 3)^4 + 6b(x - 3)^4 \\leqslant $ $ x^4 + 54x^2 + 81 $ справедливо для всех вещественных $ x $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2019',
    year: 2019,
    text: [
      '\tПлоскость $ \\pi $ проходит через три вершины прямоугольного параллелепипеда, отсекая от него тетраэдр. Два шара максимально возможных радиусов находятся внутри сферы, описанной около этого параллелепипеда, по разные стороны от плоскости $ \\pi $. Найдите отношение радиусов этих шаров, если известно, что рёбра параллелепипеда равны $ 1 $, $ \\sqrt5 $, $ 5 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2019',
    year: 2019,
    text: [
      '\tНайдите все $ (x,y) $ из интервала $ (-\\pi, \\pi] $, удовлетворяющие системе уравнений $ \\begin{cases} 36\\sqrt5 \\cos x + 9\\cos y + 4\\sqrt{10}\\cos \\frac{x+y}{2} = 12\\sqrt5 \\\\ 9\\cos x \\cos y + 4\\sqrt{10} \\cos \\frac{x+y}{2} + \\sqrt2 \\cos y \\cos \\frac{x+y}{2} = \\frac{4\\sqrt5}{3}\\end{cases}. $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2020',
    year: 2020,
    text: [
      ' Найдите наибольшее целое число, не превосходящее $ \\sqrt{\\dfrac{4^3 + 3^4}{3^4-4^3}} $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2020',
    year: 2020,
    text: [
      ' Сумма первых ста членов арифметической прогрессии равна $ 750 $. Найдите член этой прогрессии с номером $ 99 $, если известно, что второй член прогрессии равен $ 7 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2020',
    year: 2020,
    text: [' Решите уравнение $ \\sin x \\cos3x = \\sin3x \\cos5x $.']
  },
  {
    tag: 'ДВИ МГУ 2020',
    year: 2020,
    text: [
      ' Решите неравенство $ 2^{\\frac{3+5x}{1+2x}} + 2^{\\frac{1+3x}{1+2x}} \\leqslant $ $ 6\\sqrt2 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2020',
    year: 2020,
    text: [
      ' На сторонах $ AB $ и $ AC $ треугольника $ ABC $ отмечены точки $ D $ и $ E $ соответственно. Точки $ B $, $ C $, $ E $, $ D $ лежат на одной окружности. Найдите радиус окружности, описанной около треугольника $ ADC $, если известно, что $ \\angle CDE = \\angle BAC $ и что радиус окружности, описанной около треугольника $ ABC $,  равен $ 1 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2020',
    year: 2020,
    text: [
      " Дан куб $ ABCDA'B'C'D' $ с основанием $ ABCD $ и боковыми рёбрами $ AA' $, $ BB' $, $ CC' $, $ DD' $. Найдите расстояние между прямой, проходящей через середины рёбер $ AB $ и $ AA' $, и прямой, проходящей через середины рёбер $ BB' $ и $ B'C' $, если ребро куба равно $ 1 $."
    ]
  },
  {
    tag: 'ДВИ МГУ 2020',
    year: 2020,
    text: [
      ' Найдите произведение корней уравнения $ \\sin \\dfrac{x^2+x+1}{2x} + \\cos \\dfrac{x^2-x+1}{2x} = \\dfrac{x^2-4x+1}{x} \\cdot \\cos \\dfrac{\\pi-2}{4}. $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2021',
    year: 2021,
    text: [
      ' Найдите в явном виде натуральное число, заданное выражением $ \\dfrac{27^{\\frac{1}{3}}}{25^{\\frac{1}{2}}} + \\dfrac{\\log_3 25}{3\\sqrt{2} \\cdot \\cos \\frac{\\pi}{4}} + \\dfrac{41}{15} . $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2021',
    year: 2021,
    text: [
      ' Любитель коктейлей Игнат смешал $ 300 $ мл морковного сока с $ 200 $ мл сливок. Тщательно перемешав полученную смесь, Игнат попробовал её на вкус и решил, что сливок оказалось слишком много. Игнат налил в полулитровый графин $ 200 $ мл морковного сока, а оставшиеся $ 300 $ мл заполнил приготовленной смесью. Каково процентное содержание сливок в полученном напитке?'
    ]
  },
  {
    tag: 'ДВИ МГУ 2021',
    year: 2021,
    text: [' Решите уравнение $ 4\\sin 2x \\cos3x - 2 \\sin 5x = \\tg 2x. $']
  },
  {
    tag: 'ДВИ МГУ 2021',
    year: 2021,
    text: [
      ' Решите неравенство $ \\log_{x-1} \\left( 4^{\\log_3 x} - 6x^{\\log_3 2} + 10 \\right) \\leqslant $ $ 0 $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2021',
    year: 2021,
    text: [
      ' Дана равнобокая трапеция $ ABCD $ с основаниями $ AB $ и $ CD $. Известно, что окружности, вписанные в треугольники $ ABC $ и $ ACD $, касаются диагонали $ AC $ в одной и той же точке. При этом точка касания первой окружности со стороной $ BC $ делит эту сторону пополам. Найдите отношение, в котором точка касания второй окружности со стороной $ AD $ делит эту сторону, считая от точки $ A $.'
    ]
  },
  {
    tag: 'ДВИ МГУ 2021',
    year: 2021,
    text: [
      ' Найдите все пары действительных чисел $ (x, y) $ с наименьшим возможным значением у, удовлетворяющие неравенству $ \\log_{x^2 - y}\\left(x-y^2 +   \\dfrac{7}{4} \\right) \\geqslant $ $ 1. $'
    ]
  },
  {
    tag: 'ДВИ МГУ 2021',
    year: 2021,
    text: [
      ' Сфера касается всех рёбер тетраэдра $ ABCD $. Известно, что произведения длин скрещивающихся рёбер равны. Известно также, что $  AB = 3 $, $ BC = 1 $. Найдите $ AC $.'
    ]
  }
]

export default DVI
