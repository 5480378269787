const list = [
"Родился в 1986 году в г. Чебоксары",
"В 2004 году окончил СУНЦ МГУ",
"В 2009 году закончил мехмат МГУ",
"С 2005 года занимаюсь репетиторством",
"В 2009 - 2012 годах учился в аспирантуре мехмата. Работа преподавателем во всероссийской заочной математической школе и в приемной комиссии по проверке работ дополнительных вступительных испытаний в МГУ.",
"В 2013 году окончил факультет педагогического образования МГУ.",
"В 2018 году окончил Московский институт гештальт-терапии и консультирования.",
"В 2016 - 2018 годах работал преподавателем математики в центре дополнительного образования \"Эндемик\"",
"В 2017 году прошел подготовку экспертов ЕГЭ по математике, участвовал в проверке работ",
"В 2017- 2018 годах вел курсы подготовки к ЕГЭ в университете \"Синергия\" ",
"С 2018 по настоящее время работаю в преподавателем математики в Московском центре образования школьников им. Ломоносова."
]

export default list